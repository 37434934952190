import { Component } from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { Asset } from 'contentful'
import get from 'lodash/get'

import { Amount } from '../../elements/Amount'
import { ScrollArrow } from '../../elements/Arrow/Arrow'
import { Button } from '../../elements/Button/Button'
import { ContentfulImage } from '../../elements/ContentfulImage'
import { FocalImage } from '../../elements/FocalImage'
import { Type } from '../../elements/Typography/Typography'
import {
  IImageWithFocalPoint,
  ISectionImageHeaderFields,
} from '../../generated/contentful'
import { RichTextRenderer } from '../../richText/RichTextRenderer'
import { Gradient } from '../../types/custom'
import * as styles from './styles'

type ImageHeaderProps = Pick<
  ISectionImageHeaderFields,
  | 'content'
  | 'images'
  | 'textContentSide'
  | 'withDownArrow'
  | 'imagesWithFocalPoint'
> & { aboveContent?: React.ReactNode; gradient?: Gradient }

type ImageColumnProps = {
  images: (Asset | IImageWithFocalPoint)[]
  textContentSide: ISectionImageHeaderFields['textContentSide']
  gradient: Gradient
}

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => (
      <Type
        as="h2"
        bottom={{ xs: 0.5, md: 1 }}
        top={{ xs: 0.5, md: 1 }}
        weight="bold"
        size={{ xs: 32, md: 48, mlg: 64 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        preset="headlineLarge"
        as="h3"
        bottom={{ xs: 0.5, md: 0.5 }}
        top={{ xs: 1, md: 1 }}
        weight="bold"
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Type
        preset="headlineMedium"
        as="h4"
        bottom={{ xs: 1, md: 2 }}
        top={{ xs: 1, md: 1 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Type
        preset="subtitle"
        as="p"
        bottom={{ xs: 0.5, md: 1 }}
        top={{ xs: 0.5, md: 1 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Type
        preset="textSmall"
        as="p"
        bottom={{ xs: 0.5, md: 0.5 }}
        top={{ xs: 0.5, md: 0.5 }}
        weight="normal"
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type as="p" bottom={{ xs: 1 }} size={{ xs: 16, md: 24 }} weight="light">
        {children}
      </Type>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return (
            <Button
              paddingSize="large"
              preset={fields.preset}
              to={fields.link}
              bottom={{ xs: 3, md: 2.5 }}
              top={{ xs: 3, md: 2.5 }}
              emailTrigger={fields.emailTrigger}
              style={{ width: 'max-content' }}
            >
              {fields.text}
            </Button>
          )
        case 'prices':
          return (
            <Type
              top={{ xs: 0.25, md: 0.5 }}
              bottom={{ xs: 1, md: 1 }}
              size={{ xs: 14, md: 16 }}
            >
              <Amount
                value={fields.isk}
                format={fields.priceFormat}
                fallback={null}
                useSymbol
              />
            </Type>
          )
        default:
          return null
      }
    },
  },
}

const isImageWithFocalPoint = (
  image: Asset | IImageWithFocalPoint
): image is IImageWithFocalPoint =>
  'fields' in image && image.sys.contentType?.sys.id === 'imageWithFocalPoint'

const OneImageColumn = ({
  images,
  textContentSide,
  gradient,
}: ImageColumnProps) => (
  <styles.ImageContent
    textContentSide={textContentSide}
    color1={get(gradient, 'color1')}
    color2={get(gradient, 'color2')}
  >
    {images.map((image, index) => (
      <styles.SingleImage
        key={image.sys ? image.sys.id : index}
        textContentSide={textContentSide}
      >
        {isImageWithFocalPoint(image) ? (
          <FocalImage
            image={image.fields.image}
            priority={index === 0}
            focalPoint={image.fields.focalPoint}
            sizes="(max-width: 768px) 100vw,
              (max-width: 1440px) 50vw,
              50vw"
          />
        ) : (
          <ContentfulImage
            image={image}
            priority={index === 0}
            sizes="(max-width: 768px) 100vw,
              (max-width: 1440px) 50vw,
              50vw"
          />
        )}
      </styles.SingleImage>
    ))}
  </styles.ImageContent>
)

const TwoImageColumn = ({
  images,
  textContentSide,
  gradient,
}: ImageColumnProps) => (
  <styles.TwoImagesContainer textContentSide={textContentSide}>
    <styles.TwoImagesContent
      color1={get(gradient, 'color1')}
      color2={get(gradient, 'color2')}
      textContentSide={textContentSide}
    >
      {images.map((image, index) => (
        <styles.ImagePair
          key={image.sys ? image.sys.id : index}
          textContentSide={textContentSide}
        >
          {isImageWithFocalPoint(image) ? (
            <FocalImage
              priority={index === 0}
              image={image.fields.image}
              focalPoint={image.fields.focalPoint}
              sizes="(max-width: 768px) 50vw,
                    (max-width: 1440px) 20vw,
                    25vw"
            />
          ) : (
            <ContentfulImage
              image={image}
              priority={index === 0}
              sizes="(max-width: 768px) 50vw,
                    (max-width: 1440px) 20vw,
                    25vw"
            />
          )}
        </styles.ImagePair>
      ))}
    </styles.TwoImagesContent>
  </styles.TwoImagesContainer>
)

const ThreeImageColumn = ({
  images,
  textContentSide,
  gradient,
}: ImageColumnProps) => (
  <styles.ThreeImagesContainer textContentSide={textContentSide}>
    <styles.ThreeImagesContent
      color1={get(gradient, 'color1')}
      color2={get(gradient, 'color2')}
    >
      {images.map((image, index) => (
        <styles.ImageTrio key={image.sys ? image.sys.id : index}>
          {isImageWithFocalPoint(image) ? (
            <FocalImage
              priority={index === 0}
              image={image.fields.image}
              focalPoint={image.fields.focalPoint}
              sizes="(max-width: 768px) 50vw,
                    (max-width: 1440) 20vw,
                    25vw"
            />
          ) : (
            <ContentfulImage
              image={image}
              priority={index === 0}
              sizes="(max-width: 768px) 50vw,
                    (max-width: 1440px) 20vw,
                    25vw"
            />
          )}
        </styles.ImageTrio>
      ))}
    </styles.ThreeImagesContent>
  </styles.ThreeImagesContainer>
)

const calcColumns = images => {
  if (images)
    switch (images.length) {
      case 1:
        return OneImageColumn
      case 2:
        return TwoImageColumn
      case 3:
        return ThreeImageColumn
      default:
        return null
    }
  else {
    return null
  }
}

export class ImageHeader extends Component<ImageHeaderProps> {
  render() {
    const {
      content,
      gradient,
      images,
      imagesWithFocalPoint,
      textContentSide = 'left',
      aboveContent = null,
      withDownArrow = true,
    } = this.props
    // If imagesWithFocalPoint is provided, use it, otherwise use images
    const imagesToUse = imagesWithFocalPoint ?? images
    const ImageColumn = calcColumns(imagesToUse)

    return (
      <styles.StyledContainer>
        <styles.Column>
          <styles.TextContent textContentSide={textContentSide}>
            {aboveContent}

            {!!content && (
              <RichTextRenderer
                document={content}
                customOptions={documentOptions}
              />
            )}
            {withDownArrow && <ScrollArrow delay={3000} infinite />}
          </styles.TextContent>
        </styles.Column>

        {ImageColumn && (
          <ImageColumn
            images={imagesToUse}
            textContentSide={textContentSide}
            gradient={gradient}
          />
        )}
      </styles.StyledContainer>
    )
  }
}
