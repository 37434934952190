import { IImageWithFocalPointFields } from '../generated/contentful'
import { ContentfulImage, ContentfulImageProps } from './ContentfulImage'

// This type does not come from the code generation.
type FocalPoint =
  | {
      focalPoint:
        | {
            x?: number | null
            y?: number | null
          }
        | null
        | undefined
    }
  | null
  | undefined

type FocalImageProps = ContentfulImageProps &
  Pick<IImageWithFocalPointFields, 'focalPoint'>

export const FocalImage = ({ focalPoint, ...imgProps }: FocalImageProps) => {
  const width = imgProps.image?.fields?.file?.details.image.width
  const height = imgProps.image?.fields?.file?.details.image.height

  const x = (focalPoint as FocalPoint)?.focalPoint?.x
  const y = (focalPoint as FocalPoint)?.focalPoint?.y

  // Calculate the object position based on the focal point.
  // Fall back to center (50% 50%) if no focal point is set.
  const objectPosX = x && width ? (x / width) * 100 : 50
  const objectPosY = y && height ? (y / height) * 100 : 50

  return (
    <ContentfulImage
      {...imgProps}
      style={{
        ...imgProps.style,
        objectPosition: `${objectPosX}% ${objectPosY}%`,
      }}
    />
  )
}
